
import { auth } from "../providers/firebase";
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from "react";
import {motion, AnimatePresence} from 'framer-motion';

const Menu = ({ setMenuIsOpen, menuIsOpen }) => {
    
    const history = useHistory();
    
    const closeMenu = () => {
        setMenuIsOpen(false)
    }

    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (action === 'POP') {
                if (menuIsOpen) {
                    closeMenu()
                    // block route change
                    return false;
                }
            }
            if (action === 'PUSH' && menuIsOpen) {
                closeMenu()
            }
            // allow route change
            return true;
        });

        return () => {
            unblock();
        };
        // eslint-disable-next-line
    }, [menuIsOpen])

    const handlePan = (e, info) => {
        if (info.offset.x < -50) {
            closeMenu()
        }
    }

    return (
        <AnimatePresence>
            {menuIsOpen && (

                <motion.div className="absolute overflow-hidden left-0 w-full z-30" 
                    style={{ top: '60px', height: `calc(100% - 60px)` }}
                    initial="closed"
                    animate="open"
                    exit="closed"
                >
                
                    <motion.div onPanEnd={handlePan} className="absolute w-full h-full bg-gradient-to-b from-gray-700 to-gray-900" onClick={closeMenu}
                        variants={bgVariants}
                    ></motion.div>
                    
                    <motion.div className="absolute bg-white opacity-100 w-3/4 h-full transition-transform duration-300 overflow-auto"
                        variants={menuVariants}
                    >

                        <motion.div className="p-4 border-b" variants={menuItemVariants}>
                            <Link to="/logs/add-catch">
                                <div className="flex gap-3 items-center">
                                    <AddCatchIcon />
                                    <p>Add Catch</p>
                                </div>
                            </Link>
                        </motion.div>

                        <motion.div className="p-4 border-b" variants={menuItemVariants}>
                            <Link to="/bite-time">
                                <div className="flex gap-3 items-center">
                                    <BiteTimeIcon />
                                    <p>Bite Time</p>
                                </div>
                            </Link>
                        </motion.div>

                        <motion.div className="p-4 border-b" variants={menuItemVariants}>
                            <Link to="/logs">
                                <div className="flex gap-3 items-center">
                                    <LogBookIcon />
                                    <p>Logbook</p>
                                </div>
                            </Link>
                        </motion.div>

                        <motion.div className="p-4 border-b" variants={menuItemVariants}>
                            <div className="flex gap-3 items-center" onClick={() => auth.signOut()}>
                                <p>Logout</p>
                            </div>
                        </motion.div>

                    </motion.div>
                    
                </motion.div>
            )}
        </AnimatePresence>
    );
}
 
export default Menu

const AddCatchIcon = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.20808 19.6054C6.9348 20.7592 8.96487 21.375 11.0416 21.375C13.8263 21.375 16.4971 20.2688 18.4662 18.2996C20.4353 16.3305 21.5416 13.6598 21.5416 10.875C21.5416 8.7983 20.9258 6.76824 19.772 5.04152C18.6182 3.31481 16.9784 1.969 15.0597 1.17427C13.1411 0.379549 11.0299 0.171618 8.99312 0.576754C6.95632 0.981905 5.0854 1.98194 3.61695 3.45038C2.1485 4.91884 1.14847 6.78975 0.743325 8.82656C0.338179 10.8634 0.546114 12.9746 1.34084 14.8932C2.13556 16.8118 3.48137 18.4517 5.20808 19.6054ZM5.98588 3.30863C7.48237 2.30872 9.24176 1.77501 11.0416 1.77501C13.455 1.77501 15.7697 2.73375 17.4762 4.44034C19.1828 6.14691 20.1416 8.46153 20.1416 10.875C20.1416 12.6748 19.6079 14.4342 18.6079 15.9307C17.608 17.4272 16.1868 18.5936 14.524 19.2823C12.8612 19.9711 11.0315 20.1513 9.26625 19.8002C7.50102 19.449 5.87956 18.5823 4.6069 17.3097C3.33424 16.037 2.46755 14.4156 2.11642 12.6503C1.7653 10.8851 1.94551 9.05539 2.63427 7.39258C3.32302 5.72978 4.48939 4.30855 5.98588 3.30863ZM10.3388 6.625H11.7443V10.1723H15.2916V11.5777H11.7443V15.125H10.3388V11.5777H6.79157V10.1723H10.3388V6.625Z" fill="currentColor" />
    </svg>
)

const BiteTimeIcon = () => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4812 11.2156V1.03405C7.02876 0.346548 5.32639 3.19476 2.54366 6.69774C0.317466 9.50012 1.79068 14.8059 2.80556 17.1084L11.4812 11.2156Z" fill="#0B3E59" />
        <path d="M11.5 22.2483C9.32441 22.2483 7.19767 21.6032 5.38873 20.3945C3.57979 19.1858 2.16989 17.4678 1.33733 15.4578C0.504766 13.4479 0.286929 11.2361 0.711367 9.10234C1.1358 6.96855 2.18345 5.00854 3.72183 3.47016C5.26021 1.93178 7.22022 0.884133 9.35401 0.459696C11.4878 0.0352586 13.6995 0.253095 15.7095 1.08566C17.7195 1.91822 19.4375 3.32812 20.6462 5.13706C21.8549 6.946 22.5 9.07274 22.5 11.2483C22.5 14.1657 21.3411 16.9636 19.2782 19.0265C17.2153 21.0894 14.4174 22.2483 11.5 22.2483ZM11.5 1.715C9.61449 1.715 7.77132 2.27412 6.20357 3.32166C4.63582 4.36919 3.41391 5.85809 2.69235 7.60008C1.9708 9.34207 1.78201 11.2589 2.14985 13.1082C2.5177 14.9575 3.42566 16.6562 4.75892 17.9894C6.09218 19.3227 7.79086 20.2306 9.64014 20.5985C11.4894 20.9663 13.4063 20.7775 15.1483 20.056C16.8902 19.3344 18.3791 18.1125 19.4267 16.5448C20.4742 14.977 21.0333 13.1338 21.0333 11.2483C21.0333 8.71993 20.0289 6.29509 18.2411 4.50725C16.4532 2.7194 14.0284 1.715 11.5 1.715Z" fill="#021621" />
        <path d="M7.87734 13.859C7.8207 13.7806 7.78012 13.6918 7.75793 13.5977C7.73575 13.5036 7.7324 13.406 7.74807 13.3106C7.76375 13.2152 7.79814 13.1238 7.84928 13.0417C7.90041 12.9596 7.96727 12.8885 8.04601 12.8323L10.7667 10.8743V4.64834C10.7667 4.45385 10.8439 4.26732 10.9815 4.1298C11.119 3.99227 11.3055 3.91501 11.5 3.91501C11.6945 3.91501 11.881 3.99227 12.0186 4.1298C12.1561 4.26732 12.2333 4.45385 12.2333 4.64834V11.6223L8.90401 14.0277C8.82563 14.0843 8.73683 14.1249 8.6427 14.1471C8.54858 14.1693 8.451 14.1726 8.35558 14.1569C8.26016 14.1413 8.16878 14.1069 8.0867 14.0557C8.00463 14.0046 7.93347 13.9377 7.87734 13.859Z" fill="#021621" />
    </svg>
)

const LogBookIcon = () => (
    <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.8889 0.244987H13.9444C12.9422 0.244987 12.0622 0.733876 11.5 1.46721C10.9378 0.733876 10.0578 0.244987 9.05556 0.244987H1.11111C0.768889 0.244987 0.5 0.513876 0.5 0.856099V15.5228C0.5 15.865 0.768889 16.1339 1.11111 16.1339H9.05556C10.0578 16.1339 10.8889 16.965 10.8889 17.9672C10.8889 18.3094 11.1578 18.5783 11.5 18.5783C11.8422 18.5783 12.1111 18.3094 12.1111 17.9672C12.1111 16.965 12.9422 16.1339 13.9444 16.1339H21.8889C22.2311 16.1339 22.5 15.865 22.5 15.5228V0.856099C22.5 0.513876 22.2311 0.244987 21.8889 0.244987ZM10.8889 15.5228C10.3756 15.1317 9.74 14.9117 9.05556 14.9117H1.72222V1.46721H9.05556C10.0578 1.46721 10.8889 2.29832 10.8889 3.30054V15.5228ZM21.2778 14.9117H13.9444C13.26 14.9117 12.6244 15.1317 12.1111 15.5228V3.30054C12.1111 2.29832 12.9422 1.46721 13.9444 1.46721H21.2778V14.9117Z" fill="#021621" />
        <path d="M9.05543 2.68943H3.55543C3.21321 2.68943 2.94432 2.95832 2.94432 3.30054V5.74499C2.94432 6.08721 3.21321 6.3561 3.55543 6.3561H9.05543C9.39765 6.3561 9.66654 6.08721 9.66654 5.74499V3.30054C9.66654 2.95832 9.39765 2.68943 9.05543 2.68943ZM8.44432 5.13388H4.16654V3.91165H8.44432V5.13388Z" fill="#021621" />
        <path d="M19.4443 7.57832H16.9998C16.6576 7.57832 16.3887 7.84721 16.3887 8.18943V10.6339C16.3887 10.9761 16.6576 11.245 16.9998 11.245H19.4443C19.7865 11.245 20.0554 10.9761 20.0554 10.6339V8.18943C20.0554 7.84721 19.7865 7.57832 19.4443 7.57832ZM18.8332 10.0228H17.611V8.80054H18.8332V10.0228Z" fill="#021621" />
        <path d="M9.05543 7.57832H3.55543C3.21321 7.57832 2.94432 7.84721 2.94432 8.18943C2.94432 8.53165 3.21321 8.80054 3.55543 8.80054H9.05543C9.39765 8.80054 9.66654 8.53165 9.66654 8.18943C9.66654 7.84721 9.39765 7.57832 9.05543 7.57832Z" fill="#021621" />
        <path d="M9.05543 10.0228H3.55543C3.21321 10.0228 2.94432 10.2917 2.94432 10.6339C2.94432 10.9761 3.21321 11.245 3.55543 11.245H9.05543C9.39765 11.245 9.66654 10.9761 9.66654 10.6339C9.66654 10.2917 9.39765 10.0228 9.05543 10.0228Z" fill="#021621" />
        <path d="M9.05543 12.4672H3.55543C3.21321 12.4672 2.94432 12.7361 2.94432 13.0783C2.94432 13.4205 3.21321 13.6894 3.55543 13.6894H9.05543C9.39765 13.6894 9.66654 13.4205 9.66654 13.0783C9.66654 12.7361 9.39765 12.4672 9.05543 12.4672Z" fill="#021621" />
        <path d="M13.3333 3.30054C13.3333 3.64276 13.6022 3.91165 13.9444 3.91165H19.4444C19.7866 3.91165 20.0555 3.64276 20.0555 3.30054C20.0555 2.95832 19.7866 2.68943 19.4444 2.68943H13.9444C13.6022 2.68943 13.3333 2.95832 13.3333 3.30054Z" fill="#021621" />
        <path d="M19.4444 5.13388H13.9444C13.6022 5.13388 13.3333 5.40276 13.3333 5.74499C13.3333 6.08721 13.6022 6.3561 13.9444 6.3561H19.4444C19.7866 6.3561 20.0555 6.08721 20.0555 5.74499C20.0555 5.40276 19.7866 5.13388 19.4444 5.13388Z" fill="#021621" />
        <path d="M19.4444 12.4672H13.9444C13.6022 12.4672 13.3333 12.7361 13.3333 13.0783C13.3333 13.4205 13.6022 13.6894 13.9444 13.6894H19.4444C19.7866 13.6894 20.0555 13.4205 20.0555 13.0783C20.0555 12.7361 19.7866 12.4672 19.4444 12.4672Z" fill="#021621" />
        <path d="M13.9444 8.80054H15.1666C15.5088 8.80054 15.7777 8.53165 15.7777 8.18943C15.7777 7.84721 15.5088 7.57832 15.1666 7.57832H13.9444C13.6022 7.57832 13.3333 7.84721 13.3333 8.18943C13.3333 8.53165 13.6022 8.80054 13.9444 8.80054Z" fill="#021621" />
        <path d="M13.9444 11.245H15.1666C15.5088 11.245 15.7777 10.9761 15.7777 10.6339C15.7777 10.2917 15.5088 10.0228 15.1666 10.0228H13.9444C13.6022 10.0228 13.3333 10.2917 13.3333 10.6339C13.3333 10.9761 13.6022 11.245 13.9444 11.245Z" fill="#021621" />
        <rect x="4.09615" y="3.91166" width="4.37178" height="1.22222" fill="#0B3E59" />
        <path d="M17.5993 8.80055H18.9861V10.058H17.5993V8.80055Z" fill="#0B3E59" />
    </svg>
)

const bgVariants = {
    closed: {
        opacity: 0,
        transition: {
            duration: 0.6
        }
    },
    open: {
        opacity: 0.85,
        transition: {
            duration: 0.2,
        }
    },
}

const menuVariants = {
    closed: {
        x: '-100%',
        transition: {
            duration: 0.1,
            ease: 'easeInOut',
        }
    },
    open: {
        x: 0,
        transition: {
            duration: 0.1,
            ease: 'easeInOut',
            staggerChildren: 0.1,
            when: 'beforeChildren',
            delayChildren: 0.2
        }
    }
}

const menuItemVariants = {
    closed: {
        opacity: 0,
        x: -20,
    },
    open: {
        opacity: 1,
        x: 0,
    }

}
