import { Switch, Route } from "react-router-dom";
import AddCatch from "./AddCatch";
import ViewCatch from "./ViewCatch";
import LogBook from "./LogBook";
import NavBar from "../NavBar";

import { NotificationContainer } from 'react-notifications'; //https://www.npmjs.com/package/react-notifications
import ViewDaysCatch from "./ViewDaysCatch";

const LogBookLayout = () => {
	return (
		<div className="page">
			<div className="nav">
				<NavBar />
			</div>
			<div className="content">
				<Switch>
					<Route path="/logs/add-catch" component={AddCatch} />
					<Route path="/logs/view-day/:year/:month/:day" component={ViewDaysCatch} />
					<Route path="/logs/:id" component={ViewCatch} />
					<Route path="/logs" component={LogBook} />
				</Switch>
			</div>
            <NotificationContainer />
		</div>
	);
};

export default LogBookLayout;
