import CaptureWebCam from './CaptureWebCam';


const CaptureMedia = ({ handleImageSet }) => {


	return (
		<div className="CaptureMedia">
			<CaptureWebCam onImageSet={handleImageSet} />
		</div>
	);
};

export default CaptureMedia;