import { Switch, Route } from "react-router-dom";
import NavBar from "../NavBar";
import { NotificationContainer } from 'react-notifications'; //https://www.npmjs.com/package/react-notifications
import BiteTimeLocation from "./BiteTimeLocation";
import BiteTimeCalendar from "./BiteTimeCalendar";

const LogBookLayout = () => {
    return (
        <div className="page">
            <div className="nav">
                <NavBar />
            </div>
            <div className="content">
                <Switch>
                    <Route path="/bite-time/calendar" component={BiteTimeCalendar} />
                    <Route path="/bite-time" component={BiteTimeLocation} />
                </Switch>
            </div>
            <NotificationContainer />
        </div>
    );
};

export default LogBookLayout;