
export const convertDataUrlToImageFile = (dataUri) => {

    var byteString = atob(dataUri.split(',')[1]);

    // separate out the mime component
    var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    

    //New Code
    return new Blob([ab], {
        type: mimeString, 
        lastModifiedDate: new Date(),
        name: 'capturedImage.jpeg', 
    });
    
    // var blob = new Blob(byteArrays, {
    //     type: contentType,
    //     lastModifiedDate: new Date(),
    //     name: 'capturedImage.jpeg',
    // });
    // return blob;
};