import React, { useState, useEffect } from "react";
import { auth } from "./firebase";
import { login } from "../services/user";
import useAppStore from "./useAppStore";
import { getAllCatches } from "../services/catches";
import LoadingOverlay from "../components/LoadingOverlay";

export const AuthProvider = ({ children }) => {

	const [pending, setPending] = useState(true);
	const setCatches = useAppStore((state) => state.setCatches);
	const setUser = useAppStore((state) => state.setUser);

    const getCatchHistory = async () => {
        const res = await getAllCatches();
        return res;
    }

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			setPending(true);
			// console.log({ user });
			if (user) {
				let token = await user.getIdToken();
				sessionStorage.setItem("token", token);
				// console.log({  token  });

				// alert backend of login
				login(user)
					.then((res) => {
						if (res) {
							res.user.fbAuthUser = user;
							setUser(res.user);
                            getCatchHistory().then(res => {
                                setCatches(res.data);
                            });
						}
					})
					.catch((err) => console.log(err))
					.finally(() => {
						setPending(false);
					});
			} else {
				setUser(null);
				setCatches(null);
				setPending(false);
				// console.log("remove token");
				sessionStorage.removeItem("token");
			}
		});

		return () => unsubscribe();
		// eslint-disable-next-line
	}, [])

	if (pending){
		return <LoadingOverlay/>
	}

	return (
		<>{children}</>
	);
}
