import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { VictoryLine, VictoryChart, VictoryAxis, VictoryContainer } from "victory"; // https://formidable.com/open-source/victory/docs/victory-line/
import tideHighArrow from '../../../assets/images/tideHighArrow.svg'
import tideLowArrow from '../../../assets/images/tideLowArrow.svg'
import info from '../../../assets/images/info.svg'


const Tides = ({ fish }) => {
    
    const containerRef = useRef();
    const [catchTimeOffsetX, setCatchTimeOffsetX] = useState(0);
    const [chartLeftPercentage, setChartLeftPercentage] = useState(0);
    const [catchTimeFormatted, setCatchTimeFormatted] = useState(null);
    const [catchChartValue, setCatchChartValue] = useState(null);
    const graphHeight = 160

    const sampleData = [
        { x: 0, y: 74 },
        { x: 5, y: 20 },
        { x: 10, y: 80 },
        { x: 15, y: 20 },
        { x: 20, y: 80 },
        { x: 24, y: 74 },
    ]
    
    

    useEffect(() => {
        const containerWidth = containerRef.current.offsetWidth
        const totalDayMins = 24 * 60
        const catchTime = moment(fish.created_at)
        const catchHour = catchTime.minute() > 30 ? catchTime.hour() + 1 : catchTime.hour()
         
        const chartValue = sampleData.reduce((filtered, {x, y}, idx) => {
            if (x <= catchHour && sampleData[idx+1].x > catchHour) {
                const y1 = y
                const y2 = sampleData[idx + 1].y
                const x1 = x
                const x2 = sampleData[idx + 1].x
                
                const hoursDiff = x2 - x1
                const yHourIncrement = Math.abs(y2 - y1) / hoursDiff // 60 / 5 = 12
                const value = y1 > y2 ? y1 - (yHourIncrement * (catchHour - x1)) : y1 + (yHourIncrement * (catchHour - x1))
                filtered = value
            }
            return filtered
        }, 0)
        setCatchChartValue(chartValue)
        // console.log({betweenValues});
        setCatchTimeFormatted(catchTime.format('h:mma'))
        const catchDayMins = (catchTime.hour() * 60) + catchTime.minute()
        const catchDayPercent = catchDayMins / totalDayMins
        const offSetX = (containerWidth * catchDayPercent) + 20 // 20 is magic number to align correctly
        setCatchTimeOffsetX(offSetX)
        setChartLeftPercentage(Math.min(Math.max(catchDayPercent * 100, 15), 86))

        //eslint-disable-next-line
    }, [fish])


    return (
        <div ref={containerRef} className="bg-blue-900 text-white py-4">
            <div className="p-4">
                <div className="flex items-center gap-2">
                    <p className="text-xl font-bold">Tides</p>
                    <img src={info} alt="" />
                </div>
                <div className="grid grid-cols-4 mt-3 mb-6 gap-4">
                    <div className="flex items-center gap-1">
                        <img src={tideLowArrow} alt="low tide" />
                        <p>5:15am</p>
                    </div>
                    <div className="flex items-center gap-1">
                        <img src={tideHighArrow} alt="high tide" />
                        <p>10:15am</p>
                    </div>
                    <div className="flex items-center gap-1">
                        <img src={tideLowArrow} alt="ow tide" />
                        <p>3:13pm</p>
                    </div>
                    <div className="flex items-center gap-1">
                        <img src={tideHighArrow} alt="high tide" />
                        <p>8:15pm</p>
                    </div>
                </div>
            </div>

            
            <div className="absolute z-10 transform -translate-x-2/4 -translate-y-2/4 bg-blue-500 text-white rounded-3xl text-center px-2 py-2"
                style={{ left: `${chartLeftPercentage}%` }}
            >
                <b>{catchChartValue}%</b>&nbsp;{catchTimeFormatted}
            </div>
            

            <VictoryChart 
                padding={0}
                height={graphHeight}
                style={{
                    background: { fill: "#1b2d37" }
                }}

                containerComponent={ // required to stop the chart grabbing pointer events
                    <VictoryContainer
                        style={{
                            touchAction: "auto"
                        }}
                    />
                }
            >
                <VictoryLine
                    interpolation="natural"
                    data={sampleData}
                    domain={{ x: [0, 24], y: [-10, 110] }}
                    style={{
                        data: {
                            stroke: "#FFFFFF",
                            strokeWidth: 4
                        }
                    }}
                />

                
                {/* X axis graph lines */}
                <VictoryAxis
                    style={{
                        axis: { stroke: "#FFFFFF50" },
                        tickLabels: { fill: "transparent" },
                    }}
                    offsetY={(graphHeight / 4) * 2}
                />
                <VictoryAxis
                    style={{
                        axis: { stroke: "#FFFFFF10" },
                        tickLabels: { fill: "transparent" },
                    }}
                    offsetY={(graphHeight / 4) * 1}
                />
                <VictoryAxis
                    style={{
                        axis: { stroke: "#FFFFFF10" },
                        tickLabels: { fill: "transparent" },
                    }}
                    offsetY={(graphHeight / 4) * 3}
                />
                {/*  end X axis graph lines */}

                {/* Y-Axis labels */}
                <VictoryAxis dependentAxis 
                    tickValues={[0, 50, 100]}
                    tickFormat={(t) => `${Math.round(t)}%`}
                    domain={[0, 100]}
                    offsetX={50}
                    
                    style={{
                        axis: { stroke: "#FFFFFF10" },
                        tickLabels: { fontSize: 14, fill: "#9ca3af" }
                    }}
                />

                {/* Y-axis Time line */}
                <VictoryAxis dependentAxis
                    offsetX={catchTimeOffsetX}
                    // offsetX={`${chartLeftPercentage}%`}
                    style={{
                        axis: { stroke: "white" },
                        tickLabels: { fill: "transparent" },
                    }}
                />

            </VictoryChart>
            
            <div className="px-4 pt-2 pb-4">
                <div className="flex justify-between text-gray-400 text-xs">
                    <p>1am</p>
                    <p>6am</p>
                    <p>12pm</p>
                    <p>6pm</p>
                    <p>11pm</p>
                </div>
            </div>
        </div>
    );
}
 
export default Tides;