import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import { signInWithGoogle } from "../../providers/firebase";
import useAppStore from "../../providers/useAppStore";

import fishLogo from "../../assets/images/fishLogo.svg";
import googleBtn from "../../assets/images/googleSignIn.png";

const Login = () => {
	const [redirect, setRedirect] = useState(null);
	const user = useAppStore((state) => state.user);

	useEffect(() => {
		if (user) {
			setRedirect("/logs");
		}
	}, [user]);

	if (redirect) {
		return <Redirect to={redirect} />;
	}

	return (
		<div className="h-screen bg-blue-900 text-white grid place-items-center p-8 ">
			<div className="">
				<img src={fishLogo} alt="" />
			</div>
			<div className="grid place-items-center" >
				<img src={googleBtn} alt="" onClick={() => signInWithGoogle()} className="w-3/4" />
			</div>
		</div>
	);
};

export default withRouter(Login);
