import { useEffect, useState } from 'react'
import useNav from "../../hooks/useNav";
import BiteTime from '../logbook/charts/BiteTime';
import CircularProgress from '../logbook/charts/CircularProgress'
import windIcon from "../../assets/images/windIcon.svg";
import sunCloud from "../../assets/images/sunCloud.svg";
import waveArrow from "../../assets/images/waveArrow.svg";
import waveIcon from "../../assets/images/waveIcon.svg";
import Arrow from "../../assets/images/Arrow.svg";
import * as moment from "moment";
import SunPosition from '../logbook/charts/SunPosition';
import MoonPosition from '../logbook/charts/MoonPosition';
import Tides from '../logbook/charts/Tides';
import useAppStore from '../../providers/useAppStore';

const BiteTimeLocation = () => {

    useNav({navAction: 'menu', title: 'Bite Time', subAction: {icon: 'calendar', urlRoute: '/bite-time/calendar'}});
    const [location, setLocation] = useState("Onetangi");
    const [locations] = useState({
        Onetangi: {
            map: {
                lat: -36.774664,
                lng: 175.063755
            },
            data: {
                Mon: 36,
                Tue: 45,
                Wed: 76,
                Thu: 98,
                Fri: 93,
                Sat: 21,
                Sun: 55
            }
        },
        Huruhi: {
            map: {
                lat: -36.798002,
                lng: 175.017066
            },
            data: {
                Mon: 55,
                Tue: 75,
                Wed: 62,
                Thu: 66,
                Fri: 81,
                Sat: 28,
                Sun: 60
            }
        }
    });
    const [weekDays, setWeekDays] = useState(false);
    const [activeDay, setActiveDay] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);

    // get random fish as placeholder for graphs
    const catches = useAppStore((state) => state.catches);
    const [fish, setFish] = useState();


    useEffect(() => {
        let days = [];
        for (let i = 0; i < 5; i++) {
            let day = moment().add(i, 'day').format("ddd");
            let data = locations[location].data[day];
            days.push({day: day, data: data});
        };
        setWeekDays(days);

        // set fish placeholder for graphs
        if (catches) {
            setFish(catches.byId[1]);
        }

    }, [locations, location, catches]);

    return (
        <div className="h-full">
            <div className="relative">
                <div className="absolute">
                    <div className="bg-white p-3 rounded-md grid place-items-center shadow-md m-2 w-max"
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        <img src={Arrow} alt="" />
                    </div>
                    {showDropdown &&
                        <div className="bg-white p-3 rounded-md shadow-lg m-2 w-36">
                            <ul>
                                {Object.keys(locations).map((loc, i) => {
                                    return (
                                        <li className="cursor-pointer p-2"
                                            key={loc + i}
                                            onClick={() => setLocation(loc)}
                                        >
                                            <p>{loc}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    }
                </div>
                {locations &&
                    <img
                        src={`https://maps.googleapis.com/maps/api/staticmap?center=${locations[location].map.lat},${locations[location].map.lng}&zoom=14&size=800x600&key=AIzaSyBJxK-LHow2pAIF53Yg6laXZUMwnfv5gcs&markers=color:blue|${-36.9254578},${174.85110170000002}`}
                        alt="catch location"
                        className="overflow-hidden"
                    />
                }
                <div className="biteTimeFeaturedData w-14 h-14 absolute top-1/2 left-1/2 bg-white rounded-full">
                    {weekDays &&
                        <CircularProgress value={weekDays[activeDay].data} />
                    }
                </div>
            </div>
            <div className="transform -translate-y-6 rounded-2xl overflow-hidden bg-white">
                <div className="w-full grid place-items-center mt-2.5">
                    <div className="w-20 h-0.5 bg-gray-300 rounded-3xl"></div>
                </div>
                <div className="flex justify-between align-center p-6">
                    <div className="grid place-items-center">
                        <div className="h-5">
                            <img src={windIcon} alt="" />
                        </div>
                        <p className="text-2xs mt-1">SE 12-15kn</p>
                    </div>
                    <div className="grid place-items-center">
                        <div className="h-5">
                            <img src={sunCloud} alt="" />
                        </div>
                        <p className="text-2xs mt-1">Chance of Showers</p>
                    </div>
                    <div className="grid place-items-center">
                        <div className="h-5">
                            <img src={waveArrow} alt="" />
                        </div>
                        <p className="text-2xs mt-1">High at 13:35</p>
                    </div>
                    <div className="grid place-items-center">
                        <div className="h-5">
                            <img src={waveIcon} alt="" />
                        </div>
                        <p className="text-2xs mt-1">SE 1.0m at 5s</p>
                    </div>
                </div>
                <BiteTime />
                <div className="p-7 mb-9">
                    <p className="text-blue text-2xs uppercase font-semibold mt-2">THURSDAY 5th JUNE 2019</p>
                    <p className="mt-2">The best fishing is on the incoming tide into sunset with the moon in transit from 3.15pm - 6:45pm with a max rating on 89 out of 100</p>
                </div>
                {fish &&
                    <div className="mb-28">
                        <SunPosition fish={fish} />
                        <MoonPosition fish={fish} />
                        <Tides fish={fish} />
                    </div>
                }
            </div>
            <div className="fixed bottom-0 left-0 right-0 bg-white h-28 flex justify-between align-center px-4 py-2"
                style={{ boxShadow: '0px -2px 3px rgba(0, 0, 0, 0.15)'}}
            >
                {weekDays && weekDays.map((day, i) => {
                    return (
                        <div className={`grid place-items-center px-2 rounded-xl cursor-pointer ${activeDay === i ? 'bg-gray-100' : ''}`}
                                key={i}
                                onClick={() => setActiveDay(i)}
                        >
                            <p className="font-bold">{day.day}</p>
                            <div className="w-11 h-11">
                                <CircularProgress value={day.data} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default BiteTimeLocation;
