const axios = require("axios");

const catchAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_API_URL}/catch`,
});

catchAxios.interceptors.request.use(function (config) {
	const token = sessionStorage.getItem("token");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});


export async function saveCatch(details, image) {
	try {

		const formData = new FormData();
		formData.append("image", image.file);
		formData.append("data", JSON.stringify(details));

		const res = await catchAxios.post("/save", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		return res.data;
	} catch (error) {
		console.warn(error.response);
		return false;
	}
}

export async function getAllCatches() {
	try {
		const res = await catchAxios.get("/all");
		return res;
	} catch (error) {
		console.warn(error.response);
		return false;
	}
}

