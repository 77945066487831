import { useEffect, useState } from 'react'
import useNav from "../../hooks/useNav";
import CircularProgress from '../logbook/charts/CircularProgress'
import * as moment from "moment";

const BiteTimeCalendar = () => {

    useNav({ navAction: 'menu', title: 'Bite Time', subAction: { icon: 'map', urlRoute: '/bite-time' } });
    const [daysInMonth, setDaysInMonth] = useState();
    const [weekDays, setWeekDays] = useState();
    const [currentMonth, setCurrentMonth] = useState();
    const [nextMonth, setNextMonth] = useState();

    useEffect(() => {

        let currentMonth = moment().format("MMMM");
        let nextMonth = moment().add(1, 'month').format("MMMM");
        let currentMonthDays = Array.from(Array(moment().daysInMonth()), (_, i) => i + 1);
        let nextMonthDays = Array.from(Array(moment().add(1, 'month').daysInMonth()), (_, i) => i + 1);
        let currentMonthFirstDay = moment().startOf('month').format('ddd');
        let nextMonthFirstDay = moment().add(1, 'month').startOf('month').format('ddd');
        let weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

        for (let i = 0; i < weekDays.length; i++) {

            if (weekDays[i] !== currentMonthFirstDay) {
                currentMonthDays.unshift(0);
            }

            if (weekDays[i] === currentMonthFirstDay) {
                break;
            }

        }

        for (let i = 0; i < weekDays.length; i++) {

            if (weekDays[i] !== nextMonthFirstDay) {
                nextMonthDays.unshift(0);
            }

            if (weekDays[i] === nextMonthFirstDay) {
                break;
            }

        }

        setDaysInMonth([currentMonthDays, nextMonthDays]);
        setWeekDays(weekDays);
        setCurrentMonth(currentMonth);
        setNextMonth(nextMonth);

    }, [])

    return (
        <div>
            <div className="p-5">
                <h2 className="text-xl font-bold">{currentMonth}</h2>

                {daysInMonth && weekDays &&
                    <div className="grid grid-cols-7 gap-2 mt-3">
                        {weekDays.map((day, i) => {
                            return (
                                <div className="grid place-items-center" key={day + i + 'current'}>
                                    <p className="text-2xs mt-1 uppercase">{day}</p>
                                </div>
                            )
                        })}

                        {daysInMonth[0].map((day, i) => {
                            let randomValue = Math.floor(Math.random() * 90) + 10;

                            return day ?
                                <div className="grid place-items-center" key={i + 'current'}>
                                    <div className="w-9 h-9">
                                        <CircularProgress value={randomValue} />
                                    </div>
                                    <p className="text-2xs mt-1" style={{color: randomValue >= 70 ? '#A7D24D' : '#FC7D01'}}>{day}</p>
                                </div>
                            :
                                <div key={i + 'current'}></div>
                        })}
                    </div>
                }
            </div>

            <div className="p-5">
                <h2 className="text-xl font-bold">{nextMonth}</h2>

                {daysInMonth && weekDays &&
                    <div className="grid grid-cols-7 gap-2 mt-3">
                        {weekDays.map((day, i) => {
                            return (
                                <div className="grid place-items-center" key={day + i + 'next'}>
                                    <p className="text-2xs mt-1 uppercase">{day}</p>
                                </div>
                            )
                        })}

                        {daysInMonth[1].map((day, i) => {
                            let randomValue = Math.floor(Math.random() * 90) + 10;

                            return day ?
                                <div className="grid place-items-center" key={i + 'next'}>
                                    <div className="w-9 h-9">
                                        <CircularProgress value={randomValue} />
                                    </div>
                                    <p className="text-2xs mt-1" style={{color: randomValue >= 70 ? '#A7D24D' : '#FC7D01'}}>{day}</p>
                                </div>
                            :
                                <div key={i + 'next'}></div>
                        })}
                    </div>
                }
            </div>
        </div>
    );
}

export default BiteTimeCalendar;
