import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import useAppStore from '../../providers/useAppStore';
import * as moment from "moment";


import ExtraCatchDetails from './ExtraCatchDetails';
import SunPosition from './charts/SunPosition';
import MoonPosition from './charts/MoonPosition';
import Tides from './charts/Tides';
import BiteTime from './charts/BiteTime';
import useNav from '../../hooks/useNav';


const ViewCatch = () => {
    const { id } = useParams();
	const catches = useAppStore((state) => state.catches);
	useNav({navAction: 'back'});
    const [fish, setFish] = useState();
    const [catchTime, setCatchTime] = useState();

	useEffect(() => {
		if (catches) {
			console.log(catches.byId[id]);
			setFish(catches.byId[id]);
            if (catches.byId[id].details.weather){
                let date = moment(catches.byId[id].details.weather.location.localtime).format('D MMMM YYYY');
                let time = moment(catches.byId[id].details.weather.location.localtime).format('HH:mm');
                setCatchTime({
                    date: date,
                    time: time
                });
            }
		}
	}, [id, catches]);

    return (
		<div className="">
			{fish && (
				<>
					<div className="p-4">
						<h2 className="text-xl font-bold">{fish.details.species}</h2>
                        {catchTime &&
                            <p className="text-blue-500 text-xs">
								<b>{catchTime.time}</b> | {catchTime.date}
							</p>
                        }
					</div>
					<div className="">
						<img className="w-full" src={fish.image} alt="" />
					</div>
					<div className="grid grid-cols-2 gap-4 py-4 px-4">
						<div className="">
							<p>Size:</p>
							<p className="text-xl font-bold">{fish.details.size}cm</p>
						</div>
						<div className="">
							<p>Weight:</p>
							<p className="text-xl font-bold">{fish.details.weight}kg</p>
						</div>
					</div>

					{fish.details.weather && (
						<ExtraCatchDetails fish={fish} />
					)}

					{fish && <>
						<BiteTime fish={fish} />
						<SunPosition fish={fish} />
						<MoonPosition fish={fish} />
						<Tides fish={fish} />
					</>}

				</>
			)}
		</div>
	);
}

export default ViewCatch
