
import { Link } from "react-router-dom";
import useAppStore from "../../providers/useAppStore";
import moment from "moment";
import useNav from "../../hooks/useNav";

const LogBook = () => {
	const catches = useAppStore((state) => state.catches);
	
	useNav({ navAction: 'menu', title: 'Log Book' });

	return (
		<div className="flex flex-col h-full">
			<div className="flex-grow mb-12">
				{catches && catches.byDate &&
					Object.keys(catches.byDate).map((date, i) => {
						const day = moment(date, 'D MMM YYYY');
						const link = `/logs/view-day/${day.year()}/${day.month()+1}/${day.date()}`
						return (
							<div className="" key={i}>
								<div className="px-4 py-2 flex justify-between items-center">
									<div>
										<h2 className="text-xl font-bold">{catches.byDate[date].length} Fish</h2>
										<p className="">{date}</p>
									</div>
									<Link to={link} className="text-gray-500 py-4 pl-8">
										<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
										</svg>
									</Link>
								</div>
								<div className="catchesGrid" style={{ background: '#E5E5E5'}}>
									{catches.byDate[date].map((item, index) => {
										return (
											<div key={index} className="">
												<Link to={"/logs/" + item.id}>
													<img
														src={item.image}
														alt=""
													/>
												</Link>
											</div>
										);
									})}
								</div>
							</div>
						);
					})
				}
			</div>
			

			<div className="sticky bottom-0 bg-white p-4 border-t">
				<Link to="/logs/add-catch">
					<div className="bg-green-500 text-white text-xl p-3 rounded font-bold">
						<p className="text-center">+&nbsp;&nbsp;Add Catch</p>
					</div>
				</Link>
			</div>

		</div>
	);
};

export default LogBook;
