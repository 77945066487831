import React, { useEffect } from 'react';
import LocationPin from "../../assets/images/LocationPin.svg";
import windIcon from "../../assets/images/windIcon.svg";
import sunCloud from "../../assets/images/sunCloud.svg";
import temperatureIcon from "../../assets/images/temperatureIcon.svg";
import waveIcon from "../../assets/images/waveIcon.svg";

const ExtraCatchDetails = ({ fish, dayCatches=null }) => {

	const [markerString, setMarkerString] = React.useState('');

	useEffect(() => {
		let markerStringLocal = 'markers=color:black';
		if (dayCatches){
			dayCatches.forEach(catchObj => {
				markerStringLocal += `|${catchObj.details.geoData.lat},${catchObj.details.geoData.lng }`
			})
		} else {
			markerStringLocal += `|${fish.details.geoData.lat},${fish.details.geoData.lng}`
		}
		setMarkerString(markerStringLocal);
		console.log(markerStringLocal);
	}, [dayCatches, fish])

	return (
		<div className="p-4">
			<div className="flex justify-between">
				<h3 className="text-xl font-bold">Location</h3>
				<div className="flex items-center">
					<img className="w-3 mr-1" src={LocationPin} alt="" />
					<p>{fish.details.weather.location.name}</p>
				</div>
			</div>
			<div className="my-2">
				<img
					src={`https://maps.googleapis.com/maps/api/staticmap?size=800x600&zoom=14&key=AIzaSyBJxK-LHow2pAIF53Yg6laXZUMwnfv5gcs&${markerString}`}
					alt="catch location"
                    className="rounded-md"
				/>
			</div>
			<div className="mt-6">
				<h3 className="text-xl font-bold">Conditions</h3>
				<div className="">
					<div className="flex mt-4">
						<img className="mr-2 w-7" src={windIcon} alt="" />
						<p><b>{fish.details.weather.current.pressure}</b> Pressure</p>
					</div>
					<div className="flex mt-4">
						<div className="mr-2 w-7">
							<img src={waveIcon} alt="" />
						</div>
						<p><b>{fish.details.weather.current.uv_index}</b> UV Index </p>
					</div>
					<div className="flex mt-4">
						<div className="mr-2 w-7">
							<img src={temperatureIcon} alt="" />
						</div>
						<p><b>{fish.details.weather.current.feelslike}</b> Feels like </p>
					</div>
					<div className="flex mt-4">
						<div className="mr-2 w-7">
							<img src={sunCloud} alt="" />
						</div>
						<p>{fish.details.weather.current.weather_descriptions[0]}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExtraCatchDetails;
