import create from "zustand";

const useAppStore = create((set) => ({
	
	// User
	user: null,

	setUser: (user) => {
		set((state) => {
			state.user = user;
		});
	},

	// Catches
	catches: null,

	setCatches: (catches) => {
		set((state) => {
			state.catches = catches;
		});
	},

	// Navbar
	nav: {
		title: "PredictFish",
		backgroundColor: "white",
		navAction: 'menu',
		subAction: {
			icon: null,
			urlRoute: null
		}
	},
	setNav: (nav) => {
		set((state) => {
			state.nav = { ...state.nav, ...nav };
		});
	},

	fishNameMapping: {
		'blue marlin': 'Blue Marlin',
		'mahimahi': 'Mahi Mahi',
		'red drum': 'Red Drum',
		'snapper': 'Snapper',
		'rooster fish': 'Rooster Fish',
		'wahoo': 'Wahoo',
		'yellow fin tuna': 'Yellow Fin Tuna',
	},
	
}));

export default useAppStore;
