import { useEffect, useState } from "react";
import { VictoryPie, VictoryChart, VictoryContainer, VictoryAxis, VictoryLabel } from "victory"; // https://formidable.com/open-source/victory/docs/victory-line/


const CircularProgress = ({value=0}) => {

    const [percentage, setPercentage] = useState(value);
    const minGreenValue = 70
    const colors = {
        orange: '#FC7D01',
        green: '#A7D24D'
    } 

    useEffect(() => {
        setPercentage(value)
    }, [value])

    return (
           
        <VictoryChart
            padding={0}
            width={100}
            height={100}
            style={{
                background: { fill: "transparent" }
            }}
            containerComponent={ // required to stop the chart grabbing pointer events
                <VictoryContainer
                    style={{
                        touchAction: "auto"
                    }}
                />
            }
        >
            <VictoryAxis style={{
                axis: { stroke: "transparent" },
                ticks: { stroke: "transparent" },
                tickLabels: { fill: "transparent" }
            }} />
            <VictoryLabel
                textAnchor="middle" verticalAnchor="middle"
                x={50} y={50}
                text={percentage}
                style={{ 
                    fontSize: 45,
                    fontWeight: "bold",
                    fill: () => {
                        return percentage > minGreenValue ? colors.green : colors.orange;
                    }
                 }}
            />
            <VictoryPie
                style={{ 
                    labels: { 
                        fill: "transparent" 
                    },
                    data: {
                        fill: ({ datum }) => {
                            const color = datum.y > minGreenValue ? colors.green : colors.orange;
                            return datum.x === 1 ? color : "#00000020";
                        }
                    }
                }}
                cornerRadius={5}
                innerRadius={43}
                labels={() => null}
                data={[{ x: 1, y: percentage }, { x: 2, y: 100 - percentage }]}
            />
        </VictoryChart>
        
    );
}
 
export default CircularProgress;