import React, { useState, useEffect } from "react";
import CaptureMedia from './CaptureMedia';
import { saveCatch } from "../../services/catches";
import useAppStore from "../../providers/useAppStore";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "../LoadingOverlay";
import { MdFileUpload } from 'react-icons/md'
import { NotificationManager } from 'react-notifications'; //https://www.npmjs.com/package/react-notifications
import { useFilePicker } from "use-file-picker";
import { convertDataUrlToImageFile } from "../../services/images";

import RecordCatch from "./RecordCatch";


const AddCatch = () => {
	const [step, setStep] = useState("capture");
	const [imageData, setImageData] = useState();
	const [gpsData, setGpsData] = useState(null);
	const setCatches = useAppStore((state) => state.setCatches);
	const setNav = useAppStore((state) => state.setNav);
	const history = useHistory();
    const [loading, setLoading] = useState(false);

	const [openFileSelector, { filesContent, loading: uploadLoading }] = useFilePicker({
		accept: ['.jpeg', '.png'],
		multiple: false,
		readAs: 'DataURL'
	});

	// listen for file uploaded
	useEffect(() => {
		if (!loading && filesContent.length === 1) {
			handleImageSet(filesContent[0].content)
		}
		//eslint-disable-next-line
	}, [filesContent, uploadLoading])

	useEffect(() => {
		setNav({ title: "Add Catch" });
		// eslint-disable-next-line
	}, []);

	// get coords
	useEffect(() => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(function (position) {
				console.log(position);
				console.log("Latitude is :", position.coords.latitude);
				console.log("Longitude is :", position.coords.longitude);
				const data = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
					accuracy: position.coords.accuracy,
				};
				setGpsData(data);
			});
		}
	}, []);

	useEffect(() => {
		if (!imageData) {
			setStep("capture");
		}
	}, [imageData]);

	const handleImageSet = (dataUri) => {

		const file = convertDataUrlToImageFile(dataUri)
		onImageCapture({
			file,
			previewSrc: URL.createObjectURL(file),
			dataUri,
		});
	};

	const onImageCapture = async (data) => {
		setImageData(data);
        setStep("record");
	};

	const handleUploadImage = () => {
		openFileSelector();
	};

	const onDataSubmitted = async (data) => {
		setLoading(true);
		const sendData = { ...data, geoData:gpsData};
		await saveCatch(sendData, imageData).then((res) => {
			setCatches(res['allCatches']);
			setLoading(false);
			history.replace('/logs');
			history.push(`/logs/${res['latestCatch'].id}`);
		}).catch((err) => {
			console.log(err);
			setLoading(false);
			NotificationManager.error('Issue saving catch', '', 3000);
			history.push(`/logs`);
		})
	}

	const onGoBack = () => {
		setStep("capture");
	}

	return (
		<div className="AddCatch">
			{step === "capture" && (
				<div className="capture">
					<CaptureMedia
						handleImageSet={handleImageSet}
					/>
					<div className="absolute bottom-4 right-4 p-6 text-white text-4xl" onClick={handleUploadImage}>
						<MdFileUpload />
					</div>
				</div>
			)}

			{step === "record" && 
				<RecordCatch 
					imageData={imageData} 
					onDataSubmitted={onDataSubmitted}
					onGoBack={onGoBack}
				/>
			}

			
            {loading &&
                <LoadingOverlay />
            }
		</div>
	);

}

export default AddCatch;



