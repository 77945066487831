import { useEffect } from "react"
import useAppStore from "../providers/useAppStore"

const useNav = ({navAction=null, title=null, subAction=null}) => {

    const setNav = useAppStore(state => state.setNav)
    const nav = useAppStore(state => state.nav)

    useEffect(() => {
        const newNavAction = navAction ?? nav.navAction;
        const newTitle = title ?? nav.title;
        const newSubAction = subAction;
        setNav({
            navAction: newNavAction,
            title: newTitle,
            subAction: newSubAction
        });
        // eslint-disable-next-line
    }, [])

    return null

}

export default useNav