import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { NotificationManager } from 'react-notifications'; //https://www.npmjs.com/package/react-notifications
//eslint-disable-next-line
import * as tf from '@tensorflow/tfjs'; // this import has to be here - don't delete
import * as automl from '@tensorflow/tfjs-automl';
import LoadingDots from "../LoadingDots";
import ChangeSpecies from "./ChangeSpecies";
import useAppStore from "../../providers/useAppStore";
import { useHistory } from "react-router";

const RecordCatch = ({ imageData, onDataSubmitted, onGoBack }) => {

    const imgRef = useRef()
    const [requestedPredictions, setRequestedPredictions] = useState(false);
    const [modelLoading, setModelLoading] = useState(false);
    const { register, handleSubmit } = useForm();
    const [catchData, setCatchData] = useState({
        species: "",
    });
    const [showChangeSpecies, setShowChangeSpecies] = useState(false);
    const [tfPredictions, setTfPredictions] = useState(null);
    const fishNameMapping = useAppStore((state) => state.fishNameMapping);
    const history = useHistory()

    // handle back button within component
    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (action === 'POP') {
                if (showChangeSpecies){
                    setShowChangeSpecies(false)
                } else {
                    onGoBack()
                }
                return false;
            }
            return true;
        });

        return () => {
            unblock();
        };
        // eslint-disable-next-line
    }, [showChangeSpecies])

    const predictions = async () => {
        try {
            setModelLoading(true)
            setRequestedPredictions(true);
            const model = await automl.loadImageClassification('https://predictfish.la.agency/tfModels/model.json');
            const img = imgRef.current;
            const options = { centerCrop: true };
            const predictions = await model.classify(img, options);
            predictions.sort((a, b) => b.prob - a.prob);
            console.log(predictions);
            const bestPredictionSpecies = fishNameMapping[predictions[0].label];
            const bestPredictionMatch = Number(predictions[0].prob * 100).toFixed(0) + '% match'
            updateCatchData({ species: bestPredictionSpecies, match: bestPredictionMatch });
            setTfPredictions(predictions);
            // setRequestedPredictions(false);
        } catch (error) {
            console.log('prediction error', error);
            // setRequestedPredictions(false);
        } finally {
            setModelLoading(false);
        }
    }


    const onImgLoad = () => {
        if (!requestedPredictions && imageData) {
            predictions();
        }
    }

    const onSubmit = async (data) => {
        if (!data.weight || !data.size) {
            let errorMsg = '';
            if (!data.weight && !data.size) {
                errorMsg = 'Missing catch weight and length';
            } else if (!data.weight) {
                errorMsg = 'Missing catch weight';
            } else if (!data.size) {
                errorMsg = 'Missing catch length';
            }
            NotificationManager.warning(errorMsg, '', 3000);
            return;
        }

        const resData = {...data, ...catchData}

        onDataSubmitted(resData);

    };

    const updateCatchData = (updatedObject) => {
        setCatchData((currData) => {
            return { ...currData, ...updatedObject };
        });
    };

    const handleChangeSpecies = () => {
        setShowChangeSpecies(true)
    };

    const setFishSpeciesUpdate = (fish) => {
        updateCatchData(fish);
        setShowChangeSpecies(false)
    };

    return (
        <div className="record">
            <div className="imgPreview">
                {imageData && <img onLoad={onImgLoad} ref={imgRef} src={imageData.previewSrc} alt="" />}
            </div>
            <div className="captureForm">
                <p className="">Species:</p>
                <div className="flex justify-between">
                    <div>
                        {modelLoading &&
                            <LoadingDots small={true} />
                        }
                        {!modelLoading && <>
                            <h2 className="text-2xl font-bold">{catchData.species}</h2>
                            <div className="text-green-500 font-bold">{catchData.match}</div>
                        </>}
                    </div>
                    <div className="">
                        <div
                            className="cursor-pointer py-2 px-4 border border-gray-300 rounded-lg text-gray-500"
                            onClick={handleChangeSpecies}
                        >
                            Change
                        </div>
                    </div>
                </div>
                <form
                    className="mt-4"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="grid grid-cols-2 gap-6">
                        <div className="">
                            <label>Size (cm):</label>
                            <input className="bg-gray-50 border outline-none p-2 rounded mt-2 w-full"
                                type="text"
                                name="size"
                                autoComplete="off"
                                {...register("size")}
                            />
                        </div>
                        <div className="">
                            <label>Weight (kg):</label>
                            <input className="bg-gray-50 border outline-none p-2 rounded mt-2 w-full"
                                type="text"
                                name="weight"
                                autoComplete="off"
                                {...register("weight")}
                            />
                        </div>
                    </div>
                    <input
                        className="w-full mt-8 bg-green-500 text-white p-4 text-xl font-bold rounded"
                        type="submit"
                        value="Save Catch"
                    />
                </form>
            </div>
            {showChangeSpecies && (
                <ChangeSpecies
					predictions={tfPredictions} update={setFishSpeciesUpdate}
                />
                
            )}
        </div>
    );
}

export default RecordCatch;