import { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import useAppStore from "../../providers/useAppStore";
import * as moment from "moment";

import ExtraCatchDetails from './ExtraCatchDetails';
import SunPosition from './charts/SunPosition';
import MoonPosition from './charts/MoonPosition';
import Tides from './charts/Tides';
import BiteTime from './charts/BiteTime';
import useNav from '../../hooks/useNav';

const ViewDaysCatch = () => {

    const {year, month, day} = useParams();
    const catches = useAppStore((state) => state.catches);
    const [dayCatches, setDayCatches] = useState();
    const [largestCatch, setLargestCatch] = useState();
    useNav({ navAction: 'back' });


    useEffect(() => {
        if (catches) {
            const date = moment(`${month} ${day} ${year}`, 'M D YYYY').format('D MMM YYYY');
            const byDate = catches.byDate[date];

            // get the heaviest catch of the day
            const largestFish = byDate.sort((a, b) => {
                return parseInt(b.details.weight) - parseInt(a.details.weight);
            })[0];

            setLargestCatch(largestFish);
            setDayCatches(catches.byDate[date]);
        }
    }, [catches, month, day, year])

    return (
        <>
            {dayCatches && largestCatch &&  (
                <>
                    <div className="px-4 pt-4 pb-2">
                        <h2 className="text-xl font-bold">{largestCatch.date}</h2>
                        <p className="text-blue-500 text-2xs uppercase font-semibold mt-2">Biggest fish</p>
                        <h2 className="text-xl font-bold">{largestCatch.details.species}</h2>
                    </div>
                    <div className="px-4">
                        <img className="rounded" src={largestCatch.image} alt="" />
                    </div>
                    <div className="grid grid-cols-2 gap-4 px-4 pt-2 pb-4">
                        <div className="">
                            <p>Size:</p>
                            <p className="text-xl font-bold">{largestCatch.details.size}cm</p>
                        </div>
                        <div className="">
                            <p>Weight:</p>
                            <p className="text-xl font-bold">{largestCatch.details.weight}kg</p>
                        </div>
                    </div>
                    <div className="p-4">
                        <p className="text-blue-500 text-2xs uppercase font-semibold">All Catches</p>
                        <h2 className="text-xl font-bold mb-2">{dayCatches.length} Fish</h2>
                        <div className="catchesGrid gap-1">
                            {dayCatches.map((item, index) => {
                                return (
                                    <div className="" key={index}>
                                        <Link to={"/logs/" + item.id}>
                                            <img
                                                src={item.image}
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <ExtraCatchDetails fish={largestCatch} dayCatches={dayCatches} />
                    <div className="w-full h-4"></div>
                    <BiteTime fish={largestCatch} />
                    <SunPosition fish={largestCatch} />
                    <MoonPosition fish={largestCatch} />
                    <Tides fish={largestCatch} />
                </>
            )}
        </>
    );
}

export default ViewDaysCatch;
