import React from 'react';
import './App.scss';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

import LogBookLayout from "./components/logbook/LogBookLayout";
import BiteTimeLayout from "./components/biteTime/BiteTimeLayout";
import Login from './components/login/Login';
import { AuthProvider } from './providers/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import 'react-notifications/lib/notifications.css';

require('dotenv').config();

function App() {
	
	return (
		<AuthProvider>
			<div className="App">
				
				<Router>
					<Switch>
						<PrivateRoute path="/logs" component={LogBookLayout} />
						<PrivateRoute path="/bite-time" component={BiteTimeLayout} />
						<Route exact path="/login" component={Login} />
						<Route path="/">
							<Redirect to="/login" />
						</Route>
					</Switch>
				</Router>

			</div>
		</AuthProvider>
	);
}

export default App;
