import { useRef, useCallback, useState } from "react";
import Webcam from "react-webcam";

const videoConstraints = {
    facingMode: "environment",
};

const CaptureWebCam = ({ onImageSet }) => {
    
    const webcamRef = useRef(null);
    const [cameraError, setCameraError] = useState(null)

    const mediaError = (err) => {
        console.log(err);
        setCameraError(err);
    }

    // get the width of the screen
    const getScreenWidth = () => {
        return window.innerWidth;
    }

    const capture = useCallback(() => {
        const dataUrl = webcamRef.current.getScreenshot();
        const size = dataUrl.length / 1000;
        console.log(size);
        onImageSet(dataUrl);
        //eslint-disable-next-line
    },[webcamRef]);

    return (
        <div className="CaptureWebCam">
            {!cameraError && 
            <>
                <Webcam 
                    ref={webcamRef}
                    audio={false}
                    onUserMediaError={mediaError}
                    width={getScreenWidth()}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    screenshotQuality={0.7}
                />
                <div className="fixed bottom-8 left-0 right-0 grid place-items-center">
                    <div className="bg-white rounded-full h-16 w-16 border-8" onClick={capture}></div>
                </div>
                
            </>
            }
            {cameraError && 
                <div className="p-24 text-center text-white">
                    <p>Camera permission is required</p>
                </div>
            }
        </div>
    );
}
 
export default CaptureWebCam;


