import React from 'react';
import { useHistory } from 'react-router-dom';

import useAppStore from "../providers/useAppStore";
import Menu from './Menu';

const NavBar = () => {
	const nav = useAppStore((state) => state.nav);
	const history = useHistory()
	const [menuIsOpen, setMenuIsOpen] = React.useState(false);

	const handleGoBack = () => {
		history.goBack();
	};

	const handleMenu = () => {
		setMenuIsOpen(!menuIsOpen);
	};

	const handleSubAction = () => {
		history.push(nav.subAction.urlRoute);
	}


	return (
		<div className="grid grid-cols-3 p-4 place-items-center bg-gray-100 border-b">
			
			<div className="justify-self-start">
				{nav.navAction === 'back' &&
					<div className="justify-self-start" onClick={handleGoBack}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
						</svg>
					</div>
				}
				{nav.navAction === 'menu' &&
					<div className="justify-self-start" onClick={handleMenu}>
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
						</svg>
					</div>
				}
			</div>
			
			<div className="text-xl font-bold">{nav.title}</div>
			
			<div className="justify-self-end">
				{nav.subAction &&
					<div className="justify-self-start" onClick={handleSubAction}>
						{nav.subAction.icon === 'calendar' && <Calendar />}
						{nav.subAction.icon === 'map' && <Map />}
					</div>
				}
			</div>

			<Menu setMenuIsOpen={setMenuIsOpen} menuIsOpen={menuIsOpen} />

		</div>
	);
};

export default NavBar

const Calendar = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
		</svg>
	);
}

const Map = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
		</svg>
	)
}
