import React from 'react'
import LoadingDots from './LoadingDots'

const LoadingOverlay = () => {
    return (
        <div className="absolute bottom-0 top-0 right-0 left-0 bg-white grid place-items-center">
            <LoadingDots />
        </div>
    )
}

export default LoadingOverlay
