import React from 'react'

const LoadingDots = ({ small = false }) => {
    return (
        <div className={`LoadingDots ${small ? 'small' : ''}`}>
            <div className={`container ${small ? 'small' : ''}`}>
                <div className={`dot one ${small ? 'small' : ''}`}></div>
                <div className={`dot two ${small ? 'small' : ''}`}></div>
                <div className={`dot three ${small ? 'small' : ''}`}></div>
            </div>
        </div>
    )
}

export default LoadingDots
