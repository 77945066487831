const axios = require("axios");

const authedAxios = axios.create({
	baseURL: `${process.env.REACT_APP_BACKEND_API_URL}`,
});

authedAxios.interceptors.request.use(function (config) {
	const token = sessionStorage.getItem("token");
	config.headers.Authorization = token ? `Bearer ${token}` : "";
	return config;
});


export async function test(creds) {
	try {
		const res = await authedAxios.get("/user/test");
		return res.data;
	} catch (error) {
		console.warn(error.response);
		return false;
	}
}

export async function login(user) {
	try {
		const res = await authedAxios.post("/user/login", { user });
		return res.data;
	} catch (error) {
		console.warn(error.response);
		return false;
	}
}

