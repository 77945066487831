import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { VictoryLine, VictoryChart, VictoryAxis, VictoryContainer } from "victory"; // https://formidable.com/open-source/victory/docs/victory-line/
import sunrise from '../../../assets/images/sunrise.svg'
import noon from '../../../assets/images/noon.svg'
import sunset from '../../../assets/images/sunset.svg'
import info from '../../../assets/images/info.svg'


const SunPosition = ({ fish }) => {
    
    const containerRef = useRef();
    const [catchTimeOffsetX, setCatchTimeOffsetX] = useState(0);
    const [chartLeftPercentage, setChartLeftPercentage] = useState(0);
    const [catchTimeFormatted, setCatchTimeFormatted] = useState(null);
    const [catchChartValue, setCatchChartValue] = useState(null);
    const graphHeight = 160

    const sampleData = [
        { x: 0, y: -57 },
        { x: 1, y: -50 },
        { x: 2, y: -40 },
        { x: 3, y: -29 },
        { x: 4, y: -18 },
        { x: 5, y: -7 },
        { x: 6, y: 0 },
        { x: 7, y: 7 },
        { x: 8, y: 18 },
        { x: 9, y: 29 },
        { x: 10, y: 40 },
        { x: 11, y: 50 },
        { x: 12, y: 57 },
        { x: 13, y: 59 },
        { x: 14, y: 56 },
        { x: 15, y: 49 },
        { x: 16, y: 39 },
        { x: 17, y: 29 },
        { x: 18, y: 17 },
        { x: 19, y: 6 },
        { x: 20, y: 0 },
        { x: 21, y: -6 },
        { x: 22, y: -17 },
        { x: 23, y: -29 },
        { x: 24, y: -57 },
    ]
    
    useEffect(() => {
        const containerWidth = containerRef.current.offsetWidth
        const totalDayMins = 24 * 60
        const catchTime = moment(fish.created_at)
        setCatchTimeFormatted(catchTime.format('h:mma'))
        const chartValue = sampleData.find(({ x }) => x === catchTime.hour())
        setCatchChartValue(chartValue.y)
        const catchDayMins = catchTime.hour() * 60 + catchTime.minute()
        const catchDayPercent = catchDayMins / totalDayMins
        const offSetX = (containerWidth * catchDayPercent) + 20 // 20 is magic number to align correctly
        
        setCatchTimeOffsetX(offSetX)
        setChartLeftPercentage(Math.min(Math.max(catchDayPercent * 100, 15), 86))
        // eslint-disable-next-line
    }, [fish])


    return (
        <div ref={containerRef} className="bg-blue-900 text-white py-4">
            <div className="p-4">
                <div className="flex items-center gap-2">
                    <p className="text-xl font-bold">Sun Position</p>
                    <img src={info} alt="" />
                </div>
                <div className="flex mt-3 mb-6 gap-6">
                    <div className="flex items-center gap-1">
                        <img src={sunrise} alt="sunrise" />
                        <p>7:59am</p>
                    </div>
                    <div className="flex items-center gap-1">
                        <img src={noon} alt="noon" />
                        <p>12:15pm</p>
                    </div>
                    <div className="flex items-center gap-1">
                        <img src={sunset} alt="sunset" />
                        <p>7:23pm</p>
                    </div>
                </div>
            </div>

            <div className="absolute z-10 transform -translate-x-1/2 -translate-y-2/4 bg-blue-500 text-white rounded-3xl text-center px-2 py-2"
                style={{ left: `${chartLeftPercentage}%` }}
            >
                <b>{catchChartValue}°</b>&nbsp;{catchTimeFormatted}
            </div>
            

            <VictoryChart 
                padding={0}
                height={graphHeight}
                style={{
                    background: { fill: "#1b2d37" }
                }}

                containerComponent={ // required to stop the chart grabbing pointer events
                    <VictoryContainer
                        style={{
                            touchAction: "auto"
                        }}
                    />
                }
            >
                <VictoryLine
                    interpolation="natural"
                    data={sampleData}
                    domain={{ x: [0, 24], y: [-110, 110] }}
                    style={{
                        data: {
                            stroke: "#FFFFFF",
                            strokeWidth: 4
                        }
                    }}
                />

                
                {/* X axis graph lines */}
                <VictoryAxis
                    style={{
                        axis: { stroke: "#FFFFFF50" },
                        tickLabels: { fill: "transparent" },
                    }}
                />
                <VictoryAxis
                    style={{
                        axis: { stroke: "#FFFFFF10" },
                        tickLabels: { fill: "transparent" },
                    }}
                    offsetY={(graphHeight / 4) * 1}
                />
                <VictoryAxis
                    style={{
                        axis: { stroke: "#FFFFFF10" },
                        tickLabels: { fill: "transparent" },
                    }}
                    offsetY={(graphHeight / 4) * 3}
                />
                {/*  end X axis graph lines */}

                {/* Y-Axis labels */}
                <VictoryAxis dependentAxis 
                    tickValues={[-90, 0, 90]}
                    tickFormat={(t) => `${Math.round(t)}°`}
                    offsetX={50}
                    
                    style={{
                        axis: { stroke: "#FFFFFF10" },
                        tickLabels: { fontSize: 14, fill: "#9ca3af" }
                    }}
                />

                {/* Y-axis Time line */}
                <VictoryAxis dependentAxis
                    offsetX={catchTimeOffsetX}
                    style={{
                        axis: { stroke: "white" },
                        tickLabels: { fill: "transparent" },
                    }}
                />

            </VictoryChart>
            
            <div className="px-4 pt-2 pb-4">
                <div className="flex justify-between text-gray-400 text-xs">
                    <p>1am</p>
                    <p>6am</p>
                    <p>12pm</p>
                    <p>6pm</p>
                    <p>11pm</p>
                </div>
            </div>
        </div>
    );
}
 
export default SunPosition;