import React, { useEffect, useState } from "react";
import useAppStore from "../../providers/useAppStore";

const ChangeSpecies = ({ update, predictions }) => {
	
	const fishNameMapping = useAppStore((state) => state.fishNameMapping);

	const [speciesList, setSpeciesList] = useState([])
	const [filteredSpeciesList, setFilteredSpeciesList] = useState([])
	const [search, setSearch] = useState("")

	useEffect(() => {
		const speciesListItems = [];
		if (predictions) {
			// add the predictions to the top of the list
			for (const prediction of predictions) {
				const data = {
					speciesLabel: prediction.label,
					speciesDisplay: fishNameMapping[prediction.label],
					prob: (prediction.prob * 100).toFixed(0),
				}
				speciesListItems.push(data);
			}
		}

		// add the remainder of fish to the bottom of the list
		for (const label of Object.keys(fishNameMapping)) {
			if (!predictions.find((p) => p.label === label)) {
				const data = {
					speciesLabel: label,
					speciesDisplay: fishNameMapping[label],
					prob: null,
				}
				speciesListItems.push(data);
			}
		}
		console.log({speciesListItems});
		setSpeciesList(speciesListItems);
		setFilteredSpeciesList(speciesListItems);

	}, [fishNameMapping, predictions])

	const handleUpdate = (species) => {
		update({ species: species.speciesDisplay, match: 'User selected'});
	};

	useEffect(() => {
		if (!search) {
			setFilteredSpeciesList(speciesList);
		} else {
			const localFilteredSpeciesList = speciesList.filter((species) => species.speciesDisplay.toLowerCase().includes(search.toLowerCase()));
			setFilteredSpeciesList(localFilteredSpeciesList);
		}
	}, [search, speciesList])

	return (
		<div className="fixed top-0 left-0 right-0 bottom-0 bg-white overflow-auto">
			<div className="p-2 bg-gray-200 sticky top-0">
				<div className="my-2 mx-4 p-2 bg-white rounded-lg flex gap-4">
					<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M20.2882 19.8905L15.3528 14.9551C16.6542 13.4487 17.4423 11.4791 17.4423 9.32371C17.4423 4.58858 13.6384 0.75 8.94602 0.75C4.25364 0.75 0.449707 4.58858 0.449707 9.32371C0.449707 14.0588 4.25364 17.8974 8.94602 17.8974C10.7194 17.8974 12.3659 17.3491 13.7281 16.4114L18.7477 21.431C19.1731 21.8563 19.8628 21.8563 20.2882 21.431C20.7136 21.0056 20.7136 20.3159 20.2882 19.8905ZM15.2637 9.32385C15.2637 12.8448 12.4352 15.6992 8.94597 15.6992C5.45676 15.6992 2.6282 12.8448 2.6282 9.32385C2.6282 5.80286 5.45676 2.94853 8.94597 2.94853C12.4352 2.94853 15.2637 5.80286 15.2637 9.32385Z" fill="#A3A3A3" />
					</svg>
					<input type="text" placeholder="Search fish species" className="w-full outline-none" value={search} onChange={(e) => setSearch(e.target.value)} />
				</div>
			</div>
			<ul className="">
				{filteredSpeciesList.map((s, i) => {
					return (
						<li key={i} onClick={() => handleUpdate(s)} className="p-4 border-b flex justify-between">
							<p className="text-lg">{s.speciesDisplay}</p>
							{s.prob &&
								<p className="text-green-500">{s.prob}%</p>
							}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default ChangeSpecies;
