import firebase from "firebase/app";
// import 'firebase/firestore';
import 'firebase/auth';

const firebaseApp = firebase.initializeApp({
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DB_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	// storageBucket: "predict-fish-app.appspot.com",
	// messagingSenderId: "493053442215",
	appId: process.env.REACT_APP_APP_ID
});

const auth = firebaseApp.auth();

// const db = firebaseApp.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
	try {
		let creds = await auth.signInWithPopup(googleProvider);
		// console.log({ creds });

		let token = await creds.user.getIdToken();
		// console.log({ token });

		sessionStorage.setItem("token", token);
	} catch (error) {
		console.log(error.message);
	}
};

export { auth, signInWithGoogle };
