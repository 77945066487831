import { useEffect, useState } from "react";
import { VictoryLine, VictoryChart, VictoryAxis, VictoryContainer, VictoryArea } from "victory"; // https://formidable.com/open-source/victory/docs/victory-line/
import CircularProgress from "./CircularProgress";
import moment from "moment";

const BiteTime = ({fish=null}) => {
    const [catchChartValue, setCatchChartValue] = useState(null);
    const graphHeight = 160
    

    useEffect(() => {
        if (fish) {
            const catchTime = moment(fish.created_at)
            const chartValue = sampleData.find(({ x }) => x === catchTime.hour())
            setCatchChartValue(chartValue.y)
        } else {
            setCatchChartValue(null)
        }

    }, [fish])

    return (
        <div className="mt-4 mb-8">
            <div className="py-2 px-4 flex items-center justify-between">
                <p className="text-xl font-bold">Bite Time</p>
                
                <div className="w-11">
                    {catchChartValue ? <CircularProgress value={catchChartValue} /> : null}
                </div>
            </div>

            <svg className="absolute">
                <defs>
                    <linearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="30%" stopColor="#A7D24D" />
                        <stop offset="65%" stopColor="#FC7D01" />
                        <stop offset="100%" stopColor="#FC4C01" />
                    </linearGradient>
                    <linearGradient id="myGradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#A7D24D90" />
                        <stop offset="100%" stopColor="#FEFEFE10" />
                    </linearGradient>
                </defs>
            </svg>

            <VictoryChart
                padding={0}
                height={graphHeight}
                style={{
                    background: { fill: "transparent" }
                }}

                containerComponent={ // required to stop the chart grabbing pointer events
                    <VictoryContainer
                        style={{
                            touchAction: "auto"
                        }}
                    />
                }
            >
                {/* Background area gradient */}
                <VictoryArea
                    interpolation="natural"
                    style={{ 
                        data: { 
                            fill: "url(#myGradient2)",
                            strokeWidth: 1,
                            stroke: 'transparent'
                        } 
                    }}
                    data={sampleData}
                />

                <VictoryLine
                    interpolation="natural"
                    data={sampleData}
                    domain={{ x: [0, 24], y: [-10, 110] }}
                    style={{
                        data: {
                            stroke: "url(#myGradient)",
                            strokeWidth: 6
                        }
                    }}
                />


                {/* X axis graph lines */}
                <VictoryAxis
                    style={{
                        axis: { stroke: "#06304620" },
                        tickLabels: { fill: "transparent" },
                    }}
                    offsetY={(graphHeight / 4) * 2}
                />
                <VictoryAxis
                    style={{
                        axis: { stroke: "#06304620" },
                        tickLabels: { fill: "transparent" },
                    }}
                    offsetY={15}
                />

                <VictoryAxis
                    tickValues={[1, 6, 12, 18, 23]}
                    tickFormat={(t) => {
                        return t > 12 ? `${t - 12}pm` : `${t}am`
                    }}
                    offsetY={25}
                    style={{
                        axis: { stroke: "#FFFFFF10" },
                        tickLabels: { fontSize: 14, fill: "#9ca3af" }
                    }}
                />

                <VictoryAxis
                    style={{
                        axis: { stroke: "#06304620" },
                        tickLabels: { fill: "transparent" },
                    }}
                    offsetY={graphHeight - 15}
                />
                {/*  end X axis graph lines */}

                {/* Y-Axis labels */}
                <VictoryAxis dependentAxis
                    tickValues={[50, 100]}
                    tickFormat={(t) => `${Math.round(t)}`}
                    offsetX={50}
                    style={{
                        axis: { stroke: "#FFFFFF10" },
                        tickLabels: { fontSize: 14, fill: "#9ca3af" }
                    }}
                />

                

            </VictoryChart>

        </div>
    );
}
 
export default BiteTime;

const sampleData = [

    { x: 0, y: 91 },
    { x: 1, y: 85 },
    { x: 2, y: 81 },
    { x: 3, y: 72 },
    { x: 4, y: 84 },
    { x: 5, y: 67 },
    { x: 6, y: 54 },
    { x: 7, y: 46 },
    { x: 8, y: 58 },
    { x: 9, y: 38 },
    { x: 10, y: 61 },
    { x: 11, y: 89 },
    { x: 12, y: 90 },
    { x: 13, y: 76 },
    { x: 14, y: 90 },
    { x: 15, y: 67 },
    { x: 16, y: 18 },
    { x: 17, y: 8 },
    { x: 18, y: 48 },
    { x: 19, y: 42 },
    { x: 20, y: 67 },
    { x: 21, y: 89 },
    { x: 22, y: 93 },
    { x: 23, y: 56 },
    { x: 24, y: 67 },
]