import React from "react"
import { Route, Redirect } from "react-router-dom"
import useAppStore from "../providers/useAppStore";

export default function PrivateRoute({ component: Component, ...rest }) {
	
	const user = useAppStore((state) => state.user);

	return (
		<Route
			{...rest}
			render={(props) => {
				return user ? (
					<Component {...props} />
				) : (
					<Redirect to="/login" />
				);
			}}
		></Route>
	);
}
